<template>
  <div class="brand-command-page">
    <div class="brand-command-page__top">
      <vue-recaptcha
          ref="invisibleRecaptcha"
          size="invisible"
          :sitekey="siteKey"
          @verify="onRecaptchaVerify"
          loadRecaptchaScript
      >
      </vue-recaptcha>
      <div class="brand-command">
        <div class="brand-command__left">
          <h1 class="page-heading brand-command__page-heading">
            ARE YOU IN COMMAND OF YOUR BRAND?
          </h1>
          <h2 class="page-subheading brand-command__subheading">
            TAKE THIS QUICK, 10-QUESTION TEST.
          </h2>
          <div class="brand-command__tag">
            A test for economic development marketers who want to build their brand.
          </div>
          <p class="brand-command__description">This test gives you a top line
            view of your community’s branding power. Want to win your share of
            economic development projects in the future? Take full command of
            your brand NOW. Branding today is more than logos and taglines.
            It’s a comprehensive process and plan of action.</p>
        </div>
        <div class="brand-command__right">
          <div class="brand-command__right-inner">
            <transition name="pop-no-hitch" mode="out-in">
              <div class="brand-command__quiz-container" v-if="!hasStarted()">
                <div class="brand-command__start-container">
                  <div
                      class="brand-command__button"
                      @click="startQuestions()"
                  >
                    Start Test
                  </div>
                </div>
              </div>
              <div class="brand-command__quiz-container" v-else-if="hasStarted && !isFinished()">
                <div class="brand-command__question-heading">
                  Question {{ getQuestionNumber() }} of {{ getQuestionCount() }}
                </div>

                <transition name="bounce" mode="out-in">
                  <div
                      class="brand-command__question"
                      v-html="getCurrentQuestion()"
                      :key="getCurrentQuestion()"
                  ></div>
                </transition>

                <div class="brand-command__button-container">
                  <button
                      :disabled="updatingScoring"
                      class="brand-command__button brand-command__button--yes"
                      @click="handleAnswerClick(1)"
                  >Yes</button>
                  <button
                      :disabled="updatingScoring"
                      class="brand-command__button brand-command__button--no"
                      @click="handleAnswerClick(0)"
                  >No</button>
                </div>
              </div>
              <div class="brand-command__quiz-container" v-else>
                <p class="brand-command__summary" v-html="getSummary()"></p>

                <div v-if="emailSubmitted">
                  <p class="brand-command__summary">
                    Thank you for your submission.  We will send additional
                    information about Insyteful's
                    <span class="brand-command__title">BRAND<strong>COMMAND</strong></span>&#8482;
                    to <span class="brand-command__user-email">{{ email }}</span>
                  </p>
                </div>
                <div v-else>
                  <p class="brand-command__summary brand-command__summary--small-margin">
                    Enter your email below to receive more information about Insyteful's
                    <span class="brand-command__title">BRAND<strong>COMMAND</strong></span>&#8482;
                  </p>

                  <div class="brand-command__email-form">
                    <input v-model="email" class="brand-command__email-input"
                        :disabled="submittingEmail"
                        type="email" placeholder="name@example.com">
                    <button
                        :disabled="submittingEmail"
                        class="brand-command__button brand-command__button--submit"
                        @click="handleEmailSubmit()"
                    >Send</button>
                  </div>
                </div>

                <div class="brand-command__button-container">
                  <a href="https://insyteful.com/brand-command/">
                    <div
                        class="brand-command__button"
                    >
                      Learn More
                    </div>
                  </a>
                  <a href="https://insyteful.com/contact/">
                    <div
                        class="brand-command__button"
                    >
                      Contact Us
                    </div>
                  </a>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="brand-command-page__bottom"></div>
  </div>

</template>

<script>

import axios from 'axios';
import {VueRecaptcha} from 'vue-recaptcha';
export default {
  name: 'HomeComponent',
  components: {VueRecaptcha},
  data() {
    return {
      // todo remove
      temp: false,
      tempTwo: false,
      // todo end remove
      siteKey: '6Lcm7LYUAAAAACx3iFW0mBCyE2OgnMNcSU5LmW85',
      recaptcha: null,
      email: '',
      submittingEmail: false,
      emailSubmitted: false,
      questionIndex: null,
      questions: [
        'My community has a unique brand identity that stands out from the competition.',
        'I have a written list of our strengths and weaknesses, opportunities and challenges.',
        'All stakeholders know the single most effective selling message for our community.',
        'My community has a written elevator speech – a 20-second summary of our top attributes for economic developers.',
        'My community has identified target audiences and the best selling messages for each.',
        'My community has the tools to deliver on-demand data to interested prospects (available sites and buildings, workforce data, virtual tools, incentives).',
        'There is a written plan with multi-media tactics and tools to communicate our brand.',
        'All key stakeholders in our community are involved in the creation of the brand.',
        'Our website records and identifies visitors and provides full reports every day.',
        'Our website uses a lot of video to highlight people and places, projects and benefits.',
      ],
      currentValue: 0,
      scoring: {
        zeroToThree: 'In economic development, other communities are probably eating your lunch. You need a total brand makeover, a complete branding process.',
        fourToSix: 'You are fighting for your share of economic development dollars, but outside branding expertise can help your community win more often.',
        sevenToEight: 'Your community is doing it right. A review of your branding program by economic development brand specialists may take you to a higher level.',
        nineToTen: 'Congratulations. Looks like your community is hitting on all cylinders. Keep up the good work!',
      },
      updatingScoring: false,
    };
  },
  methods: {
    submitEmail() {
      let url = '/submission.php';
      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('recaptcha', this.recaptcha);
      axios.post(url, formData).then((response) => {
        console.log(response);
        this.emailSubmitted = true;
      });
    },
    onRecaptchaVerify(response) {
      this.recaptcha = response;
      this.submitEmail();
    },
    handleEmailSubmit() {
      this.submittingEmail = true;
      this.$refs.invisibleRecaptcha.execute();
    },
    getQuestionNumber() {
      return this.questionIndex + 1;
    },
    getQuestionCount() {
      return this.questions.length;
    },
    getCurrentQuestion() {
      if (this.questionIndex < this.questions.length) {
        return this.questions[this.questionIndex];
      }

      return '';
    },
    startQuestions() {
      this.questionIndex = 0;
    },
    handleAnswerClick(value) {
      let self = this;
      this.updatingScoring = true;
      this.currentValue += value;
      this.questionIndex++;
      setTimeout(function() { self.updatingScoring = false}, 1000);
    },
    hasStarted() {
      return this.questionIndex !== null;
    },
    isFinished() {
      return this.questionIndex >= this.questions.length;
    },
    getSummary() {
      switch (this.currentValue) {
        case 0:
        case 1:
        case 2:
        case 3:
          return this.scoring.zeroToThree;
        case 4:
        case 5:
        case 6:
          return this.scoring.fourToSix;
        case 7:
        case 8:
          return this.scoring.sevenToEight;
        case 9:
        case 10:
          return this.scoring.nineToTen;
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/abstracts/variables";
.brand-command-page {
  height: calc(100vh - 104px);
  max-height: calc(100vh - 104px);
  overflow: hidden;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1075px) {
    max-height: initial;
    height: auto;
    flex-direction: column-reverse;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }

  &__bottom {
    background-image: url('@/assets/images/brand-command-banner.jpg');
    background-size: cover;
    background-position: left bottom;
    height: 45vh;

    @media screen and (max-width: 1075px) {
      height: 250px;
    }

    @media screen and (max-width: 935px) {
      height: 200px;
    }
  }
}
.brand-command {
  display: flex;

  @media screen and (max-width: 1075px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__left {
    width: 50%;

    @media screen and (max-width: 1075px) {
      width: 95%;
      margin: 16px auto;
    }
  }

  &__right {
    width: 50%;

    &-inner {
      width: 80%;
      margin: 0 auto;

      @media screen and (max-width: 1275px) {
        width: 95%;
      }
    }

    @media screen and (max-width: 1075px) {
      width: 95%;
      margin: 0 auto;
    }
  }

  &__start-container {
    max-width: 300px;
    margin: 0 auto;
  }

  &__page-heading {
    margin-bottom: 16px;

    @media screen and (max-width: 625px) {
      font-size: 40px;
      line-height: 1.5em;
    }
  }

  &__subheading {
    text-align: center;

    @media screen and (max-width: 625px) {
      font-size: 20px;
      line-height: 1.5em;
    }
  }

  &__tag {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 1.5em;
    color: #D1252B;
    margin: 24px auto;

    @media screen and (max-width: 625px) {
      font-size: 20px;
    }
  }

  &__button-container {
    display: flex;
    justify-content: space-evenly;
  }

  &__button {
    margin-top: 20px;
    padding: 25px 80px;
    border: none;
    font-weight: 600;
    font-size: 24px;
    background-color: $insyteful-red;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: white;
    border-radius: 4px;

    &:hover {
      background-color: $insyteful-red-hover;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: darkgray;
      color: black;
    }

    @media screen and (max-width: 540px) {
      font-size: 20px;
      padding: 1em 2em;
    }
  }

  &__question-heading {
    font-size: 32px;
    margin: 0 auto 48px;
    text-align: center;
    font-weight: 600;
  }

  &__question {
    font-size: 24px;
    line-height: 1.5em;
    font-weight: 500;
    margin: 0 auto 24px;
    text-align: center;
  }

  &__description {
    text-align: center;

    @media screen and (max-width: 625px) {
      font-size: 20px;
      line-height: 1.5em;
    }
  }

  &__quiz-container {
    margin: 32px auto;
    background-color: rgba(144, 146, 145, 0.20);
    padding: 3rem 1rem 3rem;

    a {
      text-decoration: none;
    }
  }

  &__summary {
    text-align: center;
    margin-bottom: 32px;

    @media screen and (max-width: 625px) {
      font-size: 20px;
      line-height: 1.5em;
    }

    &--small-margin {
      margin-bottom: 16px;
    }
  }

  &__email-form {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto 48px;
  }

  &__email-input {
    cursor: auto;
    background-color: #ffffff;
    border-color: #22242626;
    color: #373a3c;
    padding: 0.5em 1em;
    line-height: 1.4em;
    font-size: 20px;
    min-height: 40px;
    border-radius: 4px;
    margin-right: 4px;
    flex-grow: 1;

    &:disabled {
      background-color: darkgray;
      cursor: not-allowed;
    }
  }

  &__user-email {
    color: $insyteful-red;
    font-weight: bold;
  }

  &__button--submit {
    padding: 0.5em 2em;
    margin: 0;
  }
}
</style>
