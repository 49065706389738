<template>
  <HeaderComponent />
  <Home msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Home from './components/Home.vue';
import HeaderComponent from '@/components/HeaderComponent';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    Home
  }
}
</script>

<style lang="scss">
  @import "~@/assets/styles/main.scss";
</style>
