<template>
    <header class="header">
      <div class="header-left">
      </div>
      <div class="header-center">
        <a href="https://insyteful.com">
          <img src="@/assets/images/logo.svg" />
        </a>
      </div>
      <div class="header-right">
      </div>
    </header>
</template>

<script>
export default {
  name: "HeaderComponent"
};
</script>

<style lang="scss" scoped>
.header {
  background-color: white;
  box-shadow: 0px 9px 10px -8px rgba(0, 0, 0, 1);
  max-width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;

  &-left,
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
  }
  &-left {
    img {
      width: 38px;
    }
  }
  &-right {
    img {
      width: 50px;
    }
  }
  &-center {
    padding: 10px 0 2px;
    img {
      cursor: pointer;
      width: 200px;
    }
  }
}
</style>
